import React from "react";
import styles from "./../../css/Testimonials.module.css"; // Import CSS module
import logo from "./../../img/FER_logo_1.png";

export default function Testimonials() {
    return (
        <div className={styles.testimonialsWrapper}> {/* Use className here */}
            <div className={styles.testimonialsMain}> {/* Use className here */}
                <img src={logo} alt="FER logo" />
                <p>
                    “Zadovoljstvo nam je surađivati s Hrvojem Sremićem i timom društva Semel pro semper d.o.o.
                    <br />
                    Višegodišnja uspješna suradnja, ažurnost, profesionalizam i pouzdanost.”
                </p>
                <div className={styles.address}>
                    <p>
                        SVEUČILIŠTE U ZAGREBU
                        <br/>
                        FAKULTET ELEKTROTEHNIKE I RAČUNARSTVA
                        <br/>
                        UNSKA 3, 10 000 ZAGREB
                    </p>
                </div>
            </div>
        </div>
    );
}
